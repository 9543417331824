import Vue from 'vue'
import VueRouter from 'vue-router'
import PrivacyComparison from '@/views/PrivacyComparison.vue'
import NProgress from 'nprogress'
import api from '@/services'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'privacy-comparison',
    component: PrivacyComparison,
    props: true,
    beforeEnter: async function (to, from, next) {
      try {
        const policies = await api.policy.getAll()
        const questions = await api.policy.getQuestions()

        to.params.policies = policies.data.map(l => ({ ...l, visible: false }))
        to.params.questions = questions.data
        next()
      } catch (error) {
        console.error(error)
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((routeTo, routeFrom, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
