<template>
  <nav class="NavBar" :class="{ 'active': isOpen }">
    <div class="NavBar-button hidden-md" @click="isOpen = !isOpen">
      <span class="nbtn-bar top"></span>
      <span class="nbtn-bar middle"></span>
      <span class="nbtn-bar bottom"></span>
    </div>
    <div class="NavBar-header">
      <a class="NavBar-brand-xs hidden-md" href="https://www.tevora.com/">
        <img src="https://www.tevora.com/wp-content/uploads/2017/10/logo-white.png" alt="tevora">
      </a>
    </div>
    <ul class="NavBar-list">
      <li class="NavBar-brand hidden-touch">
        <a class="" href="https://www.tevora.com/">
          <img src="https://www.tevora.com/wp-content/uploads/2017/10/logo-white.png" alt="tevora">
        </a>
      </li>
      <li class="NavBar-item">
        <a class="NavBar-link" href="https://www.tevora.com/privacy-policy/">Privacy Policy</a>
      </li>
      <li class="NavBar-item">
        <a class="NavBar-link" href="https://www.tevora.com/contact-us/">Contact Us</a>
      </li>
    </ul>
  </nav>
</template>
<script>

export default {
  name: 'pt-nav-bar',

  data () {
    return {
      isOpen: false,
      app: null
    }
  },

  mounted () {
    this.app = document.getElementById('app')
    this.app.addEventListener('click', this.closeNavBar)
  },

  beforeDestroy () {
    this.app.removeEventListener('click', this.closeNavBar)
  },

  methods: {
    closeNavBar (event) {
      if (event.target.id === 'app' && this.isOpen) {
        this.toggle()
      }
    },

    toggle () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
<style lang="stylus" scoped>
  $navColor = #23323C
  $textColor = #DDE2E4
  $navHeight = 6rem

  .hidden-touch
    display: none !important

  .NavBar
    height: 100%;
    width: 100%
    position: fixed
    left: -100%
    transition: all 0.3s ease
    will-change: left, width
    z-index: 999

    &
    &-header
      min-height: 3.5rem
      background-color: $navColor

    &.active
      left: 0

      & .nbtn-bar
        &.top
          opacity: 0
          transform: translateY(0.5rem)
        &.middle
          transform: translate(-0.354rem, -0.155rem) rotate(-45deg)
        &.bottom
          transform: translateY( -0.91rem) rotate(45deg)
      .NavBar-header
        right: 0
        width: 100%

    &-header
      padding: 0.7rem 1rem
      position: inherit
      right: 0
      top: 0
      width: 100%
      transition: all 0.3s ease
      display: flex
      justify-content: space-between
      align-items: center

      & svg
        cursor: pointer
        user-select: none

    &-list
      padding: 0
      margin: 0
      display: flex
      flex-flow: column nowrap
      padding-top: $navHeight
      list-style-type: none

    &-item
      list-style: none
      border-bottom: 1px solid #cccccc47
      font-size: 1rem
      color: $textColor

    &-brand
    &-link
      color: currentColor
      text-decoration: none
      cursor: pointer

    &-link
      background-color: transparent
      border: none
      display: inline-block
      width: 100%
      padding: 2rem 2rem
      font-size: 0.8rem
      font-weight: bold

      &:focus
        outline: none
    &-button
      width: 2.5rem
      height: 3rem
      cursor: pointer
      padding: 0.2rem
      margin: 0 1rem
      z-index: 1002
      position: fixed
      top: 2px
      right: 2px

      & .nbtn-bar
        display: block
        height: 0.8rem
        border-bottom: 3px solid #FFFFFF
        width: 100%
        transform: translateY(-0.2rem) rotate(0)
        transition: 450ms all
        opacity: 1

        &.top
          transition: 200ms all
    &-brand-xs
      color $textColor
      text-decoration: none
      text-align: left

      & img
        width 35%
        max-width 30%

@media screen and (min-width: 1023px)
  .hidden-md
    display: none

  .hidden-touch
    display: inherit !important

  .NavBar
    height: $navHeight
    box-shadow: none
    left: 0
    width: 100%

    &-header
      display: none

    &-list
      flex-flow: row nowrap
      justify-content: flex-start
      padding-left: 2rem
      padding-right: 2rem
      padding-top: 0
      line-height: $navHeight

    &-item
      transition: background-color 0.3s ease-out
      cursor: pointer
    &-link
      padding: 0 2rem

    &-item:hover
      background-color: rgba(#fff, 0.2)

    &-brand
      max-width: 8rem
      align-items: center
      margin-right: 1rem
      & a
        display: inherit
        height: $navHeight
      & img
          width 100%
          align-self: center

</style>
