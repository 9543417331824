import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faChevronCircleLeft, faChevronCircleRight, faSearchMinus, faSearchPlus, faFileExcel, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faBars,
  faChevronCircleLeft,
  faChevronCircleRight,
  faSearchMinus,
  faSearchPlus,
  faFileExcel,
  faExclamationTriangle
)

Vue.component('PTIcon', FontAwesomeIcon)

Vue.config.productionTip = false

const requireComponent = require.context(
  './components/ptComponents',
  false,
  /PT[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1')

  Vue.component(componentName, componentConfig.default || componentConfig)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
