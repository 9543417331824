<template>
  <footer class="Footer">
    <div class="Footer-box">
      <img
        class="Footer-brand"
        src="https://www.tevora.com/wp-content/uploads/2017/10/logo-white.png"
        alt="tevora"
      />
      <span class="Footer-item Footer-paragraph"
        >Let Tevora help you understand how privacy laws affect your
        organization and provide you with the support you need to meet
        requirements with confidence.</span
      >
      <span class="Footer-item Footer-contact"
        ><a href="mailto:sales@tevora.com">sales@tevora.com</a></span
      >
      <span class="Footer-item Footer-copy"
        >&copy; Tevora {{ currentYear }}</span
      >
    </div>
  </footer>
</template>

<script>
export default {
  name: 'pt-footer',

  data () {
    return {
      currentYear: null
    }
  },

  mounted () {
    this.currentYear = new Date().getFullYear()
  }
}
</script>

<style lang="stylus" scoped>
.Footer {
  background-color: #23323C;
  color: whitesmoke;
  min-height: 3rem;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.7rem;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;

  & a {
    color: #FFFFFF;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &-box {
    display: inherit;
    align-items: center;
    width: 100%;
  }

  &-item {
    white-space: break-spaces;
    display: inline-block;
    flex-basis: 50%;
  }

  &-copy {
    text-align: right;
  }

  &-brand {
    max-width: 8rem;
    margin: 0.5rem 1.5rem 0.5rem 0;
  }

  &-paragraph {
    display: none;
  }
}

@media screen and (min-width: 1023px) {
  .Footer {
    min-height: 5rem;
    padding: 0.3rem 2rem;

    &-contact {
      text-align: right;
    }

    &-brand {
      margin: 2rem 5rem 2rem 0;
    }

    &-paragraph {
      display: inline-block;
    }
  }
}
</style>
