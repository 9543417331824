<template>
  <ul class="ptTable">

    <li class="Header">
      <div class="Header-item">
        <span class="bold">{{ caption }}</span>
      </div>

      <template v-for="(header, index) in headers">
        <div class="Header-item" :key="`${index}-${header.id}`">
          <span v-if="header.type== 'State'"><b>{{ header.label.split('\n')[0] }}</b><br/>{{ header.label.split('\n')[1] }}</span>
          <span v-else>{{ header.label}}</span>
        </div>
      </template>
    </li>

      <li class="line" v-for="item in lineItems" :key="`${item.id}`">
        <div class="line-item">
          <span>{{ item.question }}</span>
        </div>

        <template v-for="answer in item.answers">
          <div class="line-item" :key="answer.id">
            <span v-html="answer.content || 'N/A'"></span>
          </div>
        </template>
      </li>

  </ul>
</template>
<script>
export default {
  name: 'pt-able',

  props: {
    caption: {
      type: String
    },
    headers: {
      type: Array,
      required: true
    },
    lineItems: {
      type: Array,
      required: true
    }
  }
}
</script>
<style lang="stylus" scoped>
  .ptTable
    height: inherit
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    -ms-flex-flow: column nowrap
    flex-flow: column nowrap
    list-style: none
    padding: 0
    margin: 0 1rem
    overflow: scroll
    font-weight: 500
    text-align: left
    -webkit-box-flex: 1 0 100%
    -moz-box-flex: 1 0 100%
    -webkit-flex: 1 0 100%
    -ms-flex: 1 0 100%
    flex: 1 0 100%

  .Header,
  .line
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -ms-flex-flow: row nowrap
    flex-flow: row nowrap
    background-color: #FFFFFF
    border-bottom: 1px solid #dcdcdc

    &-item
      min-height: 4rem
      height: 100%
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-box-align: center
      -ms-flex-align: center
      padding: 0.5rem
      min-width: 300px
      max-width: 300px

      & span
        width: 100%

      &:first-child
        position: -webkit-sticky
        position: sticky
        left: 0
        z-index: 1
        min-width: 200px
        max-width: 200px

  .Header
    position: -webkit-sticky
    position: sticky
    top: 0
    z-index: 2
    height: 5rem
    font-weight: 800
    color: #FFFFFF
    border-bottom: none

    &-item
      padding: 0.5rem
      text-align: center
      background-color: #FFFFFF

      &:first-child span
        background-color: #676D65
        text-align: left

    &-item span
      height: 100%
      background-color: #6FC050
      padding: 0.5rem 1rem
      border-radius:7px
      display: inline-block
      vertical-align: middle

      &:first-child span
        background-color: #676D65

  .line
    width: -webkit-fit-content
    width: -moz-fit-content
    width: fit-content
    padding: 0.5rem 0
    margin: 0 0.5rem
    -webkit-box-flex: 1 1 100%
    -moz-box-flex: 1 1 100%
    -webkit-flex: 1 1 100%
    -ms-flex: 1 1 100%
    flex: 1 1 100%

    &:nth-child(odd)
      background-color: #F6F6F6

      & .line-item:first-child
        background-color: #F6F6F6

    &-item:first-child
      background-color: #FFFFFF

</style>
