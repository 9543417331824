import { session } from './session'

export const getAll = () => {
  return session.get('/content_blocks')
}

export const getQuestions = () => {
  return session.get('/content_blocks/questions')
}

export const fetchXLSX = ids => {
  return session.post('/content_blocks/download', { ids }, { responseType: 'blob' })
}
