<template>
  <div id="app">
    <PTNavBar></PTNavBar>
    <p class="mobile-banner"><PTIcon icon="exclamation-triangle"/> This experience is optimized for larger screens.</p>
    <div class="app-view">
      <router-view/>
    </div>
    <PTFooter />
  </div>
</template>

<script>
import PTNavBar from '@/components/navigation/PTNavBar.vue'
import PTFooter from '@/components/navigation/PTFooter.vue'

export default {
  name: 'App',

  components: {
    PTNavBar,
    PTFooter
  }
}
</script>

<style lang="stylus">
$appHeight = 100vh
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap')

*
  margin: 0
  padding: 0
  box-sizing: border-box
  font-size: 14px

#app
  color #2c3e50
  height: $appHeight
  width: 100%
  font-family: 'Roboto', sans-serif
  margin: 0
  overflow: hidden

  .title
    margin: 1rem 0

body
  color: #5e5e5e

.app-view
  position: fixed
  padding: 0
  top: 6.5rem
  right: 0
  left: 0
  bottom: 3rem
  overflow: hidden

.page-title
  font-size: 2rem
  margin-bottom: 1rem

.mobile-banner
  position: fixed
  top: 3.5rem
  right: 0
  left: 0
  z-index: 1001
  padding: 0.8rem 1rem
  background-color: #F4F488
  text-shadow: 0 1px 2px rgba(0,0,0,0.2)
  font-weight: 700
  color: #CC8800
  border-bottom: 1px solid #EEAA33
  border-top: 1px solid #EEAA33

@media screen and (min-width: 1023px)
  .mobile-banner
    display: none
  .app-view
    top: 6rem
    bottom: 5rem
</style>
