<template>
  <div class="ptTable">
    <table>
      <thead class="Header">
        <th class="Header-item">
          <span class="bold">{{ caption }}</span>
        </th>

        <template v-for="(header, index) in headers">
          <th class="Header-item" :key="`${index}-${header.id}`">
            <span v-if="header.type== 'State'"><b>{{ header.label.split('\n')[0] }}</b><br/>{{ header.label.split('\n')[1] }}</span>
            <span class="bold" v-else>{{ header.label}}</span>
          </th>
        </template>
      </thead>
      <tbody>
        <tr class="line" v-for="item in lineItems" :key="`${item.id}`">
          <td class="line-item">
            <span class="bold">{{ item.question }}</span>
          </td>

          <template v-for="answer in item.answers">
            <td class="line-item" :key="answer.id">
              <span v-html="answer.content || 'N/A'"></span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'pt-able',

  props: {
    caption: {
      type: String
    },
    headers: {
      type: Array,
      required: true
    },
    lineItems: {
      type: Array,
      required: true
    }
  }
}
</script>
<style lang="stylus" scoped>
  .ptTable, table
    font-weight: 500
    overflow: auto
    width: 100%
    color: #000000
    border-spacing: 0

    & table
      position: relative
  .Header
    -webkit-position: sticky
    position: sticky
    top: 0;
    background-color: #FFFFFF
    &-item
      padding: 0.5rem
      color: #FFFFFF
      & span
        display: block
        border-radius: 13px
        height: inherit
        padding: 1rem
        background-color: #6FC050

      &:first-child span
        background-color: #676D65
  .line
    height: 100px
    &-item
      padding: 1rem 0.5rem
      text-align: left

  .line:nth-child(odd)
    background-color: #F6F6F6

  .Header,
  .line
    margin: 0 1rem;
    &-item
      width: 270px
      border-bottom: #C1C1C1 solid 1px

      &:first-child
        text-align: left

</style>
