<template>
  <button
    :class="[
      'PTButton',
      { 'PTButton-primary': primary },
      { 'PTButton-danger': danger },
      { 'PTButton-small': small },
      { 'PTButton-wide': wide }
    ]"
    type="button"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'PTButton',
  props: {
    primary: Boolean,
    danger: Boolean,
    small: Boolean,
    wide: Boolean
  }
}
</script>

<style lang="stylus" scoped>
  .PTButton
    background: #23323C
    border: 0
    border-radius: 30px
    color: #FFFFFF
    text-align: center
    cursor: pointer
    min-width: 7rem
    height: 30px
    padding 0 1rem
    line-height 30px
    margin-right: 1rem
    font-size: 1rem
    font-family: 'Roboto', Arial, Helvetica, sans-serif
    font-weight: 500
    transition: all 0.2s ease
    font-size: 13px
    margin: 0 1rem

    &:focus
      outline: none

    &:active
      box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.3) inset

    &-primary
      color: #fff
      background: #23323C

    &-danger
      color: #fff
      background: linear-gradient(#e24545, #e13c3c), #e13c3c
      border-color: #bd1d1d #bd1d1d #b41c1c

    &-small
      min-width: 4rem
      height: 1.5rem
      line-height: 1.5rem
      font-size: 13px

    &-wide
      min-width: 11rem
</style>
