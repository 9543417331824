import Axios from 'axios'

const session = Axios.create({
  baseURL: process.env.VUE_APP_BASE_ENDPOINT,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

export {
  session
}
