<template>
  <div>
    <input
      class="inp-cbx"
      type="checkbox"
      style="display: none"
      :id="id"
      :checked="isChecked"
      v-bind="$attrs"
      v-on="$listeners"
      :disabled="disabled"
    />

    <label :class="['cbx', { 'disabled' : disabled } ]" :for="id">
      <span>
        <svg width="18px" height="18px" viewbox="0 0 18 18">
          <polyline points="3 8 6 12 11 2"></polyline>
        </svg>
      </span>
      <span v-if="type== 'State'"> <b class="label">{{label.split('\n')[0]}}<br/>{{label.split('\n')[1]}}</b> </span>
      <span v-else> <b class="label">{{label}}</b> </span>
    </label>
  </div>
</template>
<script>
export default {
  name: 'pt-checkbox',
  inheritAttrs: false,

  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      required: true
    }
  }
}
</script>
<style lang="stylus" scoped>

.cbx
  margin: auto
  user-select: none
  cursor: pointer
  display: flex
  align-items: center
  margin: 1.2rem 0

  & span
    display: inline-block
    text-align: left

    &:first-child
      position: relative
      min-width: 18px
      width: 18px
      height: 18px
      border-radius: 4px
      transform: scale(1)
      vertical-align: middle
      border: 2px solid #6FC050
      transition: all 0.2s ease

    &:first-child svg
      position: absolute
      top: 0
      left: 0
      fill: none
      stroke: #23323C
      stroke-width: 2.5
      stroke-linecap: round
      stroke-linejoin: round
      stroke-dasharray: 23px
      stroke-dashoffset: 23px
      transition: all 0.3s ease
      transition-delay: 0.1s
      transform: translate3d(0, 0, 0)

    &:first-child:before
      content: ""
      width: 100%
      height: 100%
      background: #12a900
      display: block
      transform: scale(0)
      opacity: 1
      border-radius: 50%

    &:last-child
      padding-left: 8px

  &:hover span:first-child
    border-color: #0f8e00

.inp-cbx:checked + .cbx span:first-child
  border-color: #23323C
  animation: wave 0.4s ease

.inp-cbx:checked + .cbx span:first-child svg
  stroke-dashoffset: 0

.inp-cbx:checked + .cbx span:first-child:before
  transform: scale(3.5)
  opacity: 0
  transition: all 0.6s ease

.disabled
  color: gray

  & span:first-child
  &:hover span:first-child
    stroke: gray
    border-color: gray

@keyframes wave
  50%
    transform: scale(0.9)
</style>
