<template>
  <section class="comparison">
    <PTIcon icon="chevron-circle-left" class="filters-collapse" v-if="isFilterActive" @click="isFilterActive = !isFilterActive" size="2x"/>
    <PTIcon icon="chevron-circle-right" class="filters-expand" v-else @click="isFilterActive = !isFilterActive" size="2x"/>
    <aside :class="['filters', { 'collapsed': !isFilterActive }]">
      <h4 class="filters-header title">
        <span v-show="isFilterActive">Regulations</span>
      </h4>
      <p v-if="isMobile">Select 2 regulations to compare</p>
      <PTCheckbox
        class="filters-checkbox"
        v-for="(policy, index) in policyList"
        :key="`${index}-${policy._id}`"
        :id="`${index}-${policy._id}`"
        :label="policy.title"
        :isChecked="policy.visible"
        :type="policy.type"
        :disabled="policy.disabled"
        @change="toggleVisibility(policy)"
      />
    </aside>
    <div class="comparison-panel">
      <div class="comparison-actions">
        <PTIcon @click="zoomOut" icon="search-minus" inverse class="zoom-out" />
        <span>{{ zoomLevel }}%</span>
        <PTIcon @click="zoomIn" icon="search-plus" size="lg" inverse class="zoom-in" />
        <PTButton primary wide @click="downloadXLSX">Download</PTButton>
      </div>

      <PTMobileTable v-if="isMobile"
        id="privacy-comparison"
        class="comparison-table"
        caption="Privacy Regulation Comparison"
        :headers="regulationsHeaders"
        :line-items="lineItems"
      />
      <PTTable v-else
        id="privacy-comparison"
        class="comparison-table"
        caption="Privacy Regulation Comparison"
        :headers="regulationsHeaders"
        :line-items="lineItems"
      />
    </div>
  </section>
</template>

<script>

export default {
  name: 'privacy-comparison',

  props: {
    policies: {
      type: Array,
      required: true
    },
    questions: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      policyList: this.policies.map(policy => ({ ...policy, disabled: false })),
      isFilterActive: true,
      zoomLevel: 100
    }
  },

  computed: {
    isMobile () {
      return window.innerWidth < 1024
    },
    regulations () {
      return this.policyList.filter(l => l.visible)
    },
    regulationsHeaders () {
      return this.regulations.map(l => ({ type: l.type, label: l.title, value: row => row[l.title], id: l._id }))
    },

    lineItems () {
      const policiesLen = this.regulations.length

      return this.questions.map(question => {
        const model = {
          id: question.id,
          question: question.content,
          answers: []
        }

        for (let i = 0; i < policiesLen; i++) {
          const policy = this.regulations[i]
          const quest = policy.questions.find(l => l.questionId === question.id)

          if (quest) {
            model.answers.push({ id: quest._id, content: quest.answer, title: policy.title })
          }
        }

        return model
      })
    }
  },

  methods: {
    toggleVisibility (policy) {
      policy.visible = !policy.visible
      if (this.isMobile) {
        this.limitSelection()
      }
    },
    limitSelection () {
      if (this.regulations.length === 2) {
        this.policyList.map(policy => {
          policy.disabled = true
          return policy
        })
        this.policyList.map(policy => {
          policy.disabled = !policy.visible
          return policy
        })
        this.isFilterActive = false
      } else {
        this.policyList.map(policy => {
          policy.disabled = false
          return policy
        })
      }
    },
    zoomIn () {
      this.zoomLevel += 10
      const page = document.querySelector('#privacy-comparison')
      page.style.zoom = this.zoomLevel + '%'
    },

    zoomOut () {
      this.zoomLevel -= 10
      const page = document.querySelector('#privacy-comparison')
      page.style.zoom = this.zoomLevel + '%'
    },

    downloadXLSX () {
      window.location = `${process.env.VUE_APP_STATIC_ROOT}/PLC-Chart-042522.xlsx`
    }
  }
}
</script>

<style lang="stylus" scoped>
  .comparison
    height: 100%
    width: 100%
    display: flex
    position: relative
    align-items: stretch

    &-table
      flex: 1
      height: 100%
      align-self: stretch

    &-actions
      display: flex
      align-items: center
      justify-content: flex-end
      padding: 0.5rem 1rem

      .zoom
        &-in, &-out
          color: #23323C
          cursor: pointer
    &-panel
      flex: 1
      overflow-x: auto
      height: 100%
      display: flex
      flex-direction: column
      align-self: stretch

    .filters
      flex: 0 0 100%
      position: relative
      transition: all 0.1s ease
      overflow-y: auto
      background-color: #F7F9FC
      padding: 2rem 3rem

      &.collapsed
        flex-basis: 0
        overflow: hidden
        padding: 0
        & div
        & p
          display: none
      &-collapse
      &-expand
        position: absolute
        top: 0.7rem
        left: 0.7rem
        z-index: 1000
        cursor: pointer
      &-header
        display: flex
        padding-bottom: 0.7rem
        font-size: 18px

        & + p
          font-weight: 700
          color: #6FC050
          margin-bottom: 0.5rem
        & > svg
          margin-left: auto

      &-checkbox label
        line-height: 14px

@media screen and (min-width: 1023px)
  .comparison
    .filters,
    .table
      height: 81hv
    .filters
      flex: 0 0 280px
</style>
